export const btc = (n: string | number): string => {
  n = n.toString();
  // console.log("btc ", n);
  return n ? n.split(".")[0].split(",").join("") : "0";
};

export const ctc = (n: string | number): string => {
  n = n.toString();

  const m = parseInt(n ? btc(n) : "0");
  return m.toFixed(0).replace(/./g, function (c: any, i: any, a: any) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });
};

export const convertToCurrency = (
  n: string | number,
  currency: string
): string | boolean => {
  if (!n) return false;
  n = n.toString();

  const m = parseInt(n ? btc(n) : "0");
  return (
    currency +
    m.toFixed(0).replace(/./g, function (c: any, i: any, a: any) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    })
  );
};

export const pascalCase = (str = "") => {
  if (str && str !== "") {
    str = str.toString().toLowerCase();
    return str
      .split("_")
      .join(" ")
      .split(" ")
      .map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      })
      .join(" ");
  } else {
    return "";
  }
};
