import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row" }
const _hoisted_2 = { class: "flex flex-col w-full md:w-1/2" }
const _hoisted_3 = { class: "flex flex-col mb-3" }
const _hoisted_4 = { class: "flex flex-col mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col items-start"
}
const _hoisted_6 = { class: "flex flex-col pl-3 md:pl-5" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "flex flex-col w-full md:w-1/2 pl-0 md:pl-5 mt-3 md:mt-0" }
const _hoisted_9 = { class: "flex flex-col items-start mt-3" }
const _hoisted_10 = { class: "flex flex-col items-start mt-3" }
const _hoisted_11 = { class: "flex flex-col items-start mt-3" }
const _hoisted_12 = { class: "flex flex-col items-start mt-3" }
const _hoisted_13 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelWithColon = _resolveComponent("LabelWithColon")!
  const _component_ModalTemplate = _resolveComponent("ModalTemplate")!

  return (_openBlock(), _createBlock(_component_ModalTemplate, {
    "modal-id": _ctx.modalId,
    title: "Detail Data Pemasukan",
    size: "xl"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_LabelWithColon, {
              label: "Member",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Nama",
              value: _ctx.member.name,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Phone",
              value: _ctx.member.phone,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Email",
              value: _ctx.member.email,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_LabelWithColon, {
              label: "Detail Pemasukan",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Invoice",
              value: _ctx.detailDP.invoice,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Price",
              value: _ctx.convertToCurrency(_ctx.detailDP.price, 'IDR ') || '-',
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Kode Unik",
              value: _ctx.detailDP.uniqueCode || '-',
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Payment Method",
              value: _ctx.detailDP.paymentMethod,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Payment Type",
              value: _ctx.detailDP.paymentType,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Payment Status",
              value: _ctx.detailDP.paymentStatus,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Status",
              value: _ctx.detailDP.status,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ]),
          (_ctx.detailDP.paymentMethod !== 'Virtual Account')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_LabelWithColon, {
                  label: "Konfirmasi",
                  value: '',
                  "label-only": true,
                  colon: false,
                  margin: "mb-1",
                  "text-size": "text-base"
                }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_LabelWithColon, {
                    label: "Account Name",
                    value: _ctx.confirm.accountName,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createVNode(_component_LabelWithColon, {
                    label: "Account Number",
                    value: _ctx.confirm.accountNumber,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createVNode(_component_LabelWithColon, {
                    label: "Bank Name",
                    value: _ctx.confirm.bankName,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createVNode(_component_LabelWithColon, {
                    label: "Nominal",
                    value: _ctx.confirm.nominal,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createVNode(_component_LabelWithColon, {
                    label: "Status",
                    value: _ctx.confirm.status,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createVNode(_component_LabelWithColon, {
                    label: "Confirm At",
                    value: _ctx.confirm.confirmAt,
                    loading: _ctx.loading,
                    margin: "mb-1"
                  }, null, 8, ["value", "loading"]),
                  _createElementVNode("img", {
                    src: _ctx.confirm.attachment,
                    alt: "",
                    class: "rounded mt-2"
                  }, null, 8, _hoisted_7)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_LabelWithColon, {
              label: "Payment",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Account Name",
              value: _ctx.payment.accountName,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Account Number",
              value: _ctx.payment.accountNumber,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Bank Name",
              value: _ctx.payment.bankName,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Payment Type",
              value: _ctx.payment.paymentType,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_LabelWithColon, {
              label: "Referral",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Code",
              value: _ctx.refferal.code,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Name",
              value: _ctx.refferal.name,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_LabelWithColon, {
              label: "Paket",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Tipe",
              value: _ctx.paket.title,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Price",
              value: _ctx.convertToCurrency(_ctx.paket.price, 'IDR ') || '-',
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_LabelWithColon, {
              label: "Promo",
              value: '',
              "label-only": true,
              colon: false,
              margin: "mb-1",
              "text-size": "text-base"
            }),
            _createVNode(_component_LabelWithColon, {
              label: "Kode Promo",
              value: _ctx.promo.code,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"]),
            _createVNode(_component_LabelWithColon, {
              label: "Discount",
              value: _ctx.promo.totalDiscount,
              loading: _ctx.loading,
              margin: "mb-1"
            }, null, 8, ["value", "loading"])
          ])
        ])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          type: "button",
          class: "button w-20 bg-theme-6 text-white ml-auto",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
        }, " Tutup ")
      ])
    ]),
    _: 1
  }, 8, ["modal-id"]))
}