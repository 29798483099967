
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  watch,
} from "vue";
import { formatDate } from "@/utils/date-format";
import { gpf } from "@/utils/global-functions";
import { convertToCurrency } from "@/utils/text-format";
import type { DetailPemasukan } from "@/services/v1/admin/types";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const LabelWithColon = defineAsyncComponent(
  () => import(`@/components/label/WithColon.vue`)
);

const servicesV1Admin = async () => await import(`@/services/v1/admin/index`);

export default defineComponent({
  components: {
    ModalTemplate,
    LabelWithColon,
  },
  props: {
    invoiceNumber: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const modalId = "modal-detail-pemasukan";

    const detailDP = ref({} as DetailPemasukan);
    const loading = ref(false as boolean);
    const getDetailTransaksi = async () => {
      const { getTransaksiById } = await servicesV1Admin();

      loading.value = true;

      try {
        const hit = await getTransaksiById(props.invoiceNumber);
        console.log("res data ", hit);

        detailDP.value = hit.response;
        loading.value = false;
      } catch (e: any) {
        loading.value = false;

        const err = e.response;
        console.log("err response ", err);

        gpf.handleEds(e);
      }
    };

    watch(
      () => props.invoiceNumber,
      (newVal) => {
        if (newVal) getDetailTransaksi();
      }
    );

    // data confirm pemasukan
    const confirm = computed(() => {
      const dd = detailDP.value?.confirm;

      return {
        ...dd,
        accountName: dd?.accountName || "-",
        accountNumber: dd?.accountNumber || "-",
        bankName: dd?.bankName || "-",
        paymentType: dd?.paymentType || "-",
        nominal: convertToCurrency(dd?.nominal as string, "IDR ") || "-",
        status: dd?.status || "-",
        confirmAt:
          formatDate(dd?.confirmAt as string, "DD-MMM-YYYY HH:mm:ss") || "-",
        attachment: dd?.attachment || "-",
      };
    });

    // data payment pemasukan
    const payment = computed(() => {
      const dd = detailDP.value?.payment;

      return {
        ...dd,
        accountName: dd?.accountName || "-",
        accountNumber: dd?.accountNumber || "-",
        bankName: dd?.bankName || "-",
        paymentType: dd?.paymentType || "-",
      };
    });

    // data member pemasukan
    const member = computed(() => {
      const dd = detailDP.value?.member;

      return {
        ...dd,
        name: dd?.name || "-",
        phone: dd?.phone || "-",
        email: dd?.email || "-",
      };
    });

    // data refferal pemasukan
    const refferal = computed(() => {
      const dd = detailDP.value?.refferal;

      return {
        ...dd,
        name: dd?.name || "-",
        code: dd?.code || "-",
      };
    });

    // data paket pemasukan
    const paket = computed(() => {
      const dd = detailDP.value?.paket;

      return {
        ...dd,
        title: dd?.title || "-",
        price: dd?.price || "-",
      };
    });

    // data promo pemasukan
    const promo = computed(() => {
      const dd = detailDP.value?.promo;
      const discountPercent =
        dd?.code && dd?.type === "percent" && `${dd?.discount}%`;
      const discountAmount =
        dd?.code &&
        dd?.type !== "percent" &&
        convertToCurrency(dd?.discount, "IDR ");

      return {
        ...dd,
        code: dd?.code || "-",
        discount: dd?.discount || "-",
        type: dd?.type || "-",
        totalDiscount: !dd?.code ? "-" : discountPercent || discountAmount,
      };
    });

    const onCloseClick = () => {
      gpf.hideModal(`#${modalId}`);
    };

    return {
      modalId,
      detailDP,
      onCloseClick,
      convertToCurrency,
      loading,

      confirm,
      payment,
      member,
      refferal,
      paket,
      promo,
    };
  },
});
